import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import shareVideo from "../assets/share.mp4";
import logo from "../assets/logowhite.png";
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { client } from '../client.js';

export default function Login() {

    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState({});
    const [doc, setDoc] = useState({ _id: '', _type: 'user', userName: '', image: '', user:'' })

    const navigate = useNavigate();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse)

        },
        onError: (error) => console.log('Login Failed:', error),
        cookiePolicy:"single_host_origin",

    });


    useEffect(() => {

        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                }).then((res) => {

                    setProfile(res.data);
                    setDoc({
                        _id: res.data.id,
                        _type: 'user',
                        userName: res.data.name,
                        image: res.data.picture,
                    });
                })
                .catch((err) => console.log(err));


        }

    }, [user])


    useEffect(() => {

        if (doc._id !== '') {

            localStorage.setItem('user', JSON.stringify(doc));
            client.createIfNotExists(doc).then(() => {

                navigate('/shareapp/', { replace: true });
            })
        }


    }, [doc])

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <div className="flex justify-start items-center flex-col h-screen">     
            <div className="relative w-full h-full">
                <video 
                src={shareVideo}
                type="video/mp4"
                className="w-full h-full object-cover"
                loop
                muted
                autoPlay
                controls={false}
                />
            </div>
            <div className="absolute flex flex-col justify-center items-center top-0 left-0 bottom-0 right-0 bg-blackOverlay">
                <div className="p-5">
                    <img src={logo} width="130px" alt="logo" />
                </div>
                <div className="shadow-2xl">
                    <button type="button" className="bg-mainColor flex justify-center items-center p-3 rounded cursor-pointer" onClick={login}> <FcGoogle className="mr-4" /> Sign in with Google 🚀 </button>
                </div>
            </div>
        </div>
    )
}