import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdAdd, IoMdSearch } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';
export default function Navbar({ searchTerm, setSearch, user }) {
    const navigate = useNavigate();
    return (
        <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7">
	<div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
		<IoMdSearch fontSize={21} className="ml-1" />
		<input type="text" onChange={(e)=> setSearch(e.target.value)} placeholder="Search" value={searchTerm} onFocus={()=>navigate('/shareapp/search')} className="p-2 w-full bg-white outline-none" />
	</div>
	<div className="flex gap-3">
		{user ? (<><Link to={`/shareapp/user-profile/${user?._id}`} className="hidden md:block" >
		{user && <img src={user?.image} alt="user" className="rounded-lg w-14 h-12" />}
	</Link>
	<Link to={'/shareapp/create-pin'} className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center"  >
	<IoMdAdd />
</Link>
</>
)
: <button onClick={()=>navigate('/shareapp/login', { replace: true })} className="flex justify-center items-center p-3 rounded-lg  bg-red-600 hover:bg-red-700 transition-all duration-150 ease-in text-white shadow-md"><FcGoogle className="mr-3 w-5 h-5" /> Login</button>}

</div>
</div>
    );
}