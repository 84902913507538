import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from "../Components/Navbar";
import Feed from "../Components/Feed";
import PinDetails from "../Components/PinDetails";
import CreatePin from "../Components/CreatePin"; 
import Search from "../Components/Search";
import Footer from "../Pages/Footer";


export default function Pins({user}){

	const [searchTerm, setSearch] = React.useState("");

    return (
        <div className="px-2 md:px-5">
			<div className="bg-gray-50">
				<Navbar user={user} searchTerm = {searchTerm} setSearch={setSearch} />
			</div>
			<div className="h-full">
				<Routes>
					
					<Route path="/*" element={<Feed />} />
					<Route path="/category/:categoryId" element={<Feed />} />
					<Route path="/pin-detail/:pinId" element={<PinDetails user={user} />} />
					<Route path="/create-pin" element={<CreatePin user={user} />} />
					<Route path="/search" element={<Search searchTerm={searchTerm} setSearch={setSearch} />} />

				</Routes>


			</div>
		</div>
    )


}