import React, {useState, useEffect} from 'react';


export default function LikeButton({id, like, unLike, alreadyLiked, likeArr}) {
    return ( <>
        {!alreadyLiked ? (<button className=" flex bg-red-600 w-9 h-9 rounded-full justify-center items-center shadow-md opacity-75 hover:opacity-100" onClick={(e)=>{
        		e.stopPropagation();
				e.preventDefault();
				like(id);
        }} >


        		<i className="fa fa-thumbs-up" style={{color:"white"}}></i>


        	</button>) : (<button className=" flex bg-stone-900 rounded-md justify-center items-center shadow-md opacity-75 hover:opacity-100 p-2 text-xs" onClick={(e)=>{
        		e.stopPropagation();
				e.preventDefault();
				unLike(id);
        }}>

        		<div className="flex flex-col">
        			<i className="fa fa-check mr-1" style={{color:"white"}}></i>
        		</div>
        		<span className="" style={{color: 'white'}}>{likeArr?.length !== 1 ? `${likeArr?.length} likes` : `${likeArr?.length} like`  }</span>
        	</button>) } 
        


        </>)
    }