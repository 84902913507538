import React, { useState, useRef, useEffect } from 'react';
import { HiMenu } from 'react-icons/hi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, Route, Routes } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import UserProfile from '../Components/UserProfile';
import logo from '../assets/logo.png';
import Pins from './Pins';
import {userQuery} from '../utils/data.js';
import {client} from '../client';
import {fetchUser} from '../utils/fetchUser';
import Footer from "../Pages/Footer";

export default function Home() {

    const [toggleSidebar, setToggleSidebar] = useState(false);

    const [user, setUser] = useState(null);

    const userInfo = fetchUser();

    const scrollRef = useRef(null);

    useEffect(()=>{

        const query = userQuery(userInfo?._id);
        
        client.fetch(query).then((data)=>{
            setUser(data[0]);
        })

    }, []);

    useEffect(()=>{

        scrollRef.current.scrollTo(0,0);


    }, [])

    return (
        <>
        <div className="flex bg-gray-50 md:flex-row flex-col h-screen transition-height duration-75 ease-out">
            <div className="hidden md:flex h-screen flex-initial">
                <Sidebar user={user && user} />
        </div>
        <div className="flex md:hidden flex-row">
            <div className="p-2 w-full flex flex-row justify-between items-center shadow-md">
                <HiMenu fontSize={40} className="cursor-pointer" onClick={() => setToggleSidebar(true)} />
                <Link to="/shareapp/">
                   {/* <img src={logo} alt="logo" className="w-28" />*/}
                   DreamDex
                </Link>
                {user && (<Link to={`shareapp/user-profile/${user?._id}`}>
                                    <img src={user?.image} alt="user-pic" className="w-9 h-9 rounded-full " />
                                </Link>)}
            </div>
        
        {toggleSidebar && (
        <div className="fixed w-3/5 bg-white h-screen overflow-y-auto shadow-md z-10 animate-slide-in">
          <div className="absolute w-full flex justify-end items-center p-2">

            <i class="fa-solid fa-circle-xmark fa-lg pt-5 py-3 cursor-pointer transition-all duration-200 ease-in" onClick={() => setToggleSidebar(false)}></i>
          </div>
          <Sidebar closeToggle={setToggleSidebar} user={user && user} />
        </div>
        )}
      </div>
      <div className="pb-2 flex-1 h-screen overflow-y-scroll" ref={scrollRef}>
        <Routes>
          <Route path="/user-profile/:userId" element={<UserProfile userLog={userInfo?._id} />} />
          <Route path="/*" element={<Pins user={user && user} />} />
        </Routes>
      </div>

    </div>
    <div className="bg-gray-200 hide-footer">
        <Footer />
    </div>
    </>
  );
};
       
