import React from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Login from "./Pages/Login";
import Home from "./Pages/Home";


export default function App() {


    return (

    	<Routes>
    		<Route path="/shareapp/login" element={<Login />} />
    		<Route path="/shareapp/*" element={<Home />} />
            
    	</Routes>

    );


}