import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import {RiHomeFill} from 'react-icons/ri';
import {IoIosArrowForward} from 'react-icons/io';
import logo from '../assets/logo.png';
import {categories} from  '../utils/data.js';

export default function Sidebar({user, closeToggle}){

	const handleCloseSidebar = ()=>{

		if(closeToggle) closeToggle(false);
	}



	const isNotActiveStyle  = "flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize"; 
	const isActiveStyle  = "flex items-center px-5 gap-3 font-extrabold border-r-2 border-black transition-all duration-200 ease-in-out capitalize";


    return (
        <div className=" justify-between bg-white h-full overflow-y-scroll min-w-210 no-scrollbar ">
			<div className='flex flex-col'>
				<Link to='/shareapp/' className="flex px-5 gap-2 w-190 my-6 pt-1 items-center" onClick={handleCloseSidebar}>
					{/*<img src={logo} alt='logo' className="select-none w-full cursor-pointer" />*/}
					<span className="text-3xl">DreamDex</span>
				</Link>
			</div>
			<div className="flex flex-col gap-5 ">
				<NavLink to='/shareapp//' className={({isActive})=> isActive ? isActiveStyle : isNotActiveStyle} onClick={handleCloseSidebar}> 
					<i class="fa-sharp fa-solid fa-house" style={{color: "#61656b"}}></i>
					Home
				</NavLink>
				<h3 className="mt-2 px-5 text-lg 2xl:text-xl select-none">Discover Categories</h3>
				<div className="mb-5">{categories.slice(0, categories.length-1).map((item, index)=>{
					return <div className="mb-4"><NavLink 
							to={`/shareapp/category/${item.name}`} 
							className={({isActive})=> isActive ? isActiveStyle : isNotActiveStyle} 
							onClick={handleCloseSidebar} 
							key={categories.name}>
							<img src={item?.image} className="w-8 h-8 rounded-full shadow-md" alt="category" />
						{item.name}
					</NavLink></div>
					
				})}</div>
			</div>
		</div>
    )


}