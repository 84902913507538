import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
export default function Footer() {
    return (
        <div className="flex flex-col relative">
	<div className="flex flex-row justify-between items-center p-10 m-5">
		<div className=" flex flex-col text-base w-1/2 border-r-2 border-gray-400 px-3">
			<Link to={'/shareapp/'}>{/*<img src={logo} alt="logo" className="w-28" />*/}
			<span className="text-2xl">DreamDex</span>
			</Link>
			<div className="pt-2 border-double border-r-2 text-base sm:text-sm xs:text-xs ">
				
				<strong>Welcome to DreamDex - Your Ultimate Pin Sharing Platform</strong> <br />
				Welcome to DreamDex, the premier destination for creating and sharing pins! Whether you're an artist, designer, marketer, or simply someone with a creative spark, ShareApp provides you with the tools and community to unleash your imagination and share your creations with the world.
			</div>
			
		</div>
		<div className="flex flex-col leading-10 p-3 m-auto ">
			<h2 className="pt-2">Contact the Devs</h2>
			<div className="select-none">
				<strong>Name:</strong> Ahmad
			</div>
			<div className="text-left select-none">
				<strong>Email:</strong> <a className="" href="mailto:ahmadaliirfan32@gmail.com">ahmadaliirfan32@gmail.com</a>
			</div>
		</div>
	</div>
	<div className="text-center bg-gray-600 w-full p-2 text-white "><h1 className="text-lg">@Copyright 2024</h1></div>
	
</div>
    )
}