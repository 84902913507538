import { urlFor } from '../client';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MdDownloadForOffline } from "react-icons/md";
import { AiTwotoneDelete } from 'react-icons/ai';
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { fetchUser } from '../utils/fetchUser';
import { client } from "../client.js";
import {ShareSocial} from 'react-share-social';
import ShareButton from './ShareButton';
import LikeButton from './LikeButton';

export default function Pin({ pin: { postedBy, image, _id, destination, save, like }, clickSave, setClickSave }) {
    const [postHovered, setPostHovered] = useState(false);
    const navigate = useNavigate();
    const user = fetchUser();
    const alreadySaved = !!(save?.filter((item) => item?.postedBy?._id === user?._id))?.length;
    const alreadyLiked = !!(like?.filter((item) => item?.postedBy?._id === user?._id))?.length;
    const handleMouseEnter = () => {
        setPostHovered(true);
    };
    const handleMouseLeave = () => {
        setPostHovered(false);
    };

    const isMobile = function() {
  		const minWidth = 600;
  		return window.innerWidth < minWidth || screen.width < minWidth;
	};

    const savePin = (id) => {
        if (!alreadySaved) {
            const response = client.patch(id).setIfMissing({ save: [] }).insert('after', 'save[-1]', [{
                _key: uuidv4(),
                userId: user?._id,
                postedBy: {
                    _type: 'postedBy',
                    _ref: user?._id,
                },
            }]).commit().then((Updated) => {
                setClickSave(!clickSave);
                
            
            });
           
        } else {
            console.log("Mate it never entered, check ur if statement");
        }
    }

    const unSavePin = (id) =>{

    	client.patch(id).unset([`save[userId=="${user?._id}"]`]).commit().then(()=>{

    	setClickSave(!clickSave);
    		

    	});

  

    }

    const likePin = (id) => {

    	if(!alreadyLiked){

    		const response = client.patch(id).setIfMissing({ like: [] }).insert('after', 'like[-1]', [{
                _key: uuidv4(),
                userId: user?._id,
                postedBy: {
                    _type: 'postedBy',
                    _ref: user?._id,
                },
            }]).commit().then((Updated) => {
                setClickSave(!clickSave);
                
            
            });


    	}


    }


        const unLike = (id) =>{

    	client.patch(id).unset([`like[userId=="${user?._id}"]`]).commit().then(()=>{

    	setClickSave(!clickSave);
    		

    	})
    };


    const deletePin = (id) => {
        client.delete(id).then(() => {
            window.location.reload();
        })
    }

    useEffect(()=>{

    	if(isMobile()){
    		setPostHovered(true);
    	}

    },[])



    return (
        <div className="m-2">
	<div
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
		onClick={() => navigate(`/shareapp/pin-detail/${_id}`)}
		className="relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
		>
		<img className="rounded-lg w-full" alt="user-post" src={urlFor(image).width(250).url()} />
		{postHovered && (
		<div className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-50" style={{ height: '100%' }}>
			<div className="flex items-center justify-between">
				<div className="flex gap-2">
					<a
						href={`${(image?.asset?.url)}?dl=`}
						download
						onClick={(e) => e.stopPropagation()}
						className=" bg-white w-9 h-9 p-2 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none"
						>
						<MdDownloadForOffline />
					</a>
				</div>				
				{alreadySaved ? (<button type="button" className="bg-red-600 px-3 py-2 text-sm text-white rounded-3xl opacity-70 hover:shadow-md hover:opacity-100"
				onClick = {(e)=>{
				e.stopPropagation();
				e.preventDefault();
				unSavePin(_id);

				}}
				> {save?.length ? save.length === 1 ? "1 User" : `${save?.length} Users`  : 0} Saved
				</button>) : (<button type="button" className="bg-red-600 px-3 py-2 text-sm text-white rounded-3xl opacity-70 hover:shadow-md hover:opacity-100"
				onClick = {(e) =>{
				
				e.stopPropagation();
				e.preventDefault();
				savePin(_id);
				}}
				> Save </button>)}
			</div>
			<div className="flex justify-between">
				<ShareButton postedBy={postedBy} image={image} id={_id} dest={destination} />
				{user?._id && <LikeButton id={_id} like={likePin} unLike={unLike} alreadyLiked={alreadyLiked} likeArr={like} />}	
			</div>
			

			<div className="flex justify-between items-center gap-2 w-full">
				{destination && (
				<a href={destination} target="blank" rel="noreferer" className="bg-white flex items-center gap-2 text-black font-bold p-2 pl-4 pr-4 rounded-full opacity-70 hover:opacity-100 hover:shadow-md text-xs" >
					<BsFillArrowUpRightCircleFill />
					{destination.length > 13 ? `${destination.slice(0, 13)}...` : destination}
					
				</a>
				)}
				{postedBy?._id === user?._id && (
				<button type="button" onClick={(e)=>{
				e.stopPropagation();
				e.preventDefault();
				deletePin(_id);
				}}
				className="bg-black px-3 py-2 text-base text-white rounded-3xl opacity-70 hover:shadow-md hover:opacity-100"
				>
				
				<AiTwotoneDelete />
					
					</button>
					)}
					
				</div>
			</div>
			)}
		</div>
		<Link to={`user-profile/${postedBy?._id}`}
			className="flex gap-2 mt-2 items-center">
			<img className="w-8 h-8 object-cover rounded-full"
			src={postedBy?.image}
			alt='user-profile' />
			<p className="font-semibold capitalize ">{postedBy?.userName}</p>
			
		</Link>
	</div>
    );
}