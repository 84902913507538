import React from 'react';
import {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {client} from '../client';
import MasonryLayout from './MasonryLayout';
import Spinner from './Spinner';
import {searchQuery, feedQuery} from '../utils/data.js';

export default function Feed(){

	const [loading, setLoading] = useState(false);
	const {categoryId} = useParams();
	const [pins, setPins] = useState(null);
	const [update, setUpdate] = useState(false);
	const [clickSave, setClickSave] = useState(false);


	useEffect(()=>{

		setLoading(true);

		if(categoryId){

			const query = searchQuery(categoryId);


				client.fetch(query).then((data)=>{
				setPins(data);
				setLoading(false);
				setUpdate(true);


			});




		}else{

			client.fetch(feedQuery).then((data)=>{
				setPins(data);
				setLoading(false);
				setUpdate(true);

			});


			
		}


	}, [categoryId, update, clickSave]);


	if(loading) return <Spinner message="We are adding new ideas to your feed" />

	if(!pins?.length) return <h2 className="flex items-center justify-center">No Pins Available! Add now!</h2>

	return(

		<div>
			<div>

				{pins && <MasonryLayout pins={pins} clickSave={clickSave} setClickSave={setClickSave} />}

			</div>


		</div>

		);

}