import React from 'react';
import {Hourglass} from 'react-loader-spinner';

export default function Spinner({message}){

	return (

		<div className="flex flex-col justify-center items-center w-full h-full">
			
				
			<Hourglass type="Circles"
			width={200}
			height={50}
			className="m-5"
				/>
				<p className="items-center text-lg text-center px-2">{message}</p>
			</div>


			);


}