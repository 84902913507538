import React, { useState, useEffect } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';


import { userCreatedPinsQuery, userQuery, userSavedPinsQuery } from '../utils/data.js';
import { client } from '../client.js';
import MasonryLayout from './MasonryLayout';
import Spinner from './Spinner';

const activeBtnStyles = 'bg-red-500 text-white font-bold p-2 rounded-full w-20 outline-none';
const notActiveBtnStyles = 'bg-primary mr-4 text-black font-bold p-2 rounded-full w-20 outline-none';

export default function UserProfile({userLog}) {
    

    const [user, setUser] = useState(null);
    const [pins, setPins] = useState(null);
    const [text, setText] = useState('Created'); //Saved
    const [activeBtn, setActiveButton] = useState('Created');
    const navigate = useNavigate();
    const { userId } = useParams();
    
    const randomImage = 'https://source.unsplash.com/1600x900/?nature,photogrophy,technology'; 

    useEffect(() => {
        const query = userQuery(userId);
        client.fetch(query).then((data) => {
            setUser(data[0]);
        })
    }, [userId]);


    useEffect(()=>{

    	if(text === 'Created'){

    		const createdPinsQuery = userCreatedPinsQuery(userId);

    		client.fetch(createdPinsQuery).then((data)=>{

    			setPins(data);

    		});

    	}else{

    		const savedPinsQuery = userSavedPinsQuery(userId);

    		client.fetch(savedPinsQuery).then((data)=>{

    			setPins(data);

    		});

    	}


    }, [text, userId])

    const logOut = () => {
        googleLogout();
        setUser(null);
        localStorage.clear();
        navigate('/shareapp/login');
    };



    if (!user) {
        return <Spinner message="Loading Profile" />
    }
    return (
        <div className="relative pb-2 h-full justify-center items-center">
	<div className="flex flex-col pb-5">
		<div className="relative flex flex-col mb-7"><div className="flex flex-col justify-center items-center">
			<img 
			src={randomImage}
			className="w-full h-370 xl:h-510 shadow-lg object-cover"
			alt="banner-pic"/>

			<img 
			src={user.image}
			className="rounded-full w-20 h-20 -mt-10 shadow-xl object-cover"
			alt="user-pic"
			/>

			<h1 className="font-bold text-3xl text-center mt-3">
				{user.userName}
			</h1>
			<div className='absolute top-0 z-1 right-0 p-2'>
				{userId === userLog && (

					<button 
						type="button" 
						className="flex text-center text-base bg-red-600 text-white w-full justify-between items-center p-3 rounded-md shadow-md hover:bg-red-700 transition-all duration-100 ease-in "
						onClick={logOut}
						> 
						<AiOutlineLogout className="mr-5 w-5 h-5" /> Logout 
					</button>

					)}
			</div>
		</div>
		<div className="text-center mb-7">

			<button type="button" onClick={(e)=>{
				setText(e.target.textContent)
				setActiveButton('Created');

			}}

			className={`${activeBtn === 'Created' ? activeBtnStyles : notActiveBtnStyles}`}

			>Created</button>
			<button type="button" onClick={(e)=>{
				setText(e.target.textContent)
				setActiveButton('Saved');

			}}

			className={`${activeBtn === 'Saved' ? activeBtnStyles : notActiveBtnStyles}`}

			>Saved</button>

		</div>

		{pins?.length ? (<div className="px-2">
			<MasonryLayout pins={pins} />
		</div>) : (<div className="flex justify-center font-bold items-center w-full text-lg mt-2">No Pins Found!</div>) }

	</div>
</div>
</div>
    )
}