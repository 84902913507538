import React, {useState, useEffect} from 'react';
import { client, urlFor } from '../client';
import { pinDetailMorePinQuery, pinDetailQuery } from '../utils/data.js';

export default function ShareButton(id){

	const [share, setShare] = useState(false);
	const [pins, setPins] = useState(null);
	const [pinDetail, setPinDetail] = useState(null);
	const [ifMobile, setIfMobile] = useState(false);
	const fetchPinDetails = () => {
    	let query = pinDetailQuery(id.id);
        client.fetch(query).then((data) => {
            setPinDetail(data[0]);
            if (data[0]) {
                query = pinDetailMorePinQuery(data[0]);
                client.fetch(query).then((res) => {

                });
            }
        })
    }

    function isMobile(){

    	const minWidth = 768;

    	if(touchSupport() && window.innerWidth < minWidth || screen.width < minWidth){

    		setIfMobile(true);

    	}else{
			setIfMobile(false);    		
    	}

    }

    function touchSupport(){

    	return 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    }

   useEffect(() => {
        fetchPinDetails();
         }, [id.id])

   useEffect(()=>{

   	isMobile()

   }, [])

	return(

		<div className="flex justify-left items-center w-full">
			<div className="flex absolute justify-center items-center">
				<button className="bg-white w-9 h-9 p-2 items-center opacity-75 hover:opacity-100 rounded-full"
				onClick={(e)=>{
					e.stopPropagation();
					e.preventDefault();
					setShare(true);
				

				}}

				> <i class="fa-solid fa-share" style={{color:"#000000"}}></i>

				</button>

				{share && <div className="flex bg-none z-50 animate-slide-in rounded-lg shadow-md" onClick={(e)=>{
					e.stopPropagation();
					e.preventDefault();

				}}>
					<div class="elfsight-app-bada9014-2105-424b-b24d-0517b0accfe3" data-elfsight-app-lazy data-elfsight-app-custom-url={`https://ahmadali.webhostingfree.io/shareapp/pin-detail/${id.id}`}></div>
									<button onClick={(e)=>{
										e.stopPropagation();
										e.preventDefault();
										setShare(false)}} class="fa fa-xmark w-50 p-3 opacity-75 hover:opacity-100 cursor-pointer transition-all duration-200 ease-in" style={{color:"white", background:"#3B5998"}} ></button>
				
				
				</div>}
			</div>
		</div>
		
		);



}